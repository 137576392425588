import * as React from 'react';

const QuoteImageBlock = React.lazy(
  () =>
    import(
      /* webpackChunkName: "QuoteImageBlock-Chunk" */ './organisms/QuoteImageBlock/QuoteImageBlockWrapper'
    ),
);

const TechnicalDownloadSearch = React.lazy(
  () =>
    import(
      /* webpackChunkName: "TechnicalDownloadSearch-Chunk" */ './organisms/TechnicalDownloadSearch/TechnicalDownloadSearchWrapper'
    ),
);

const PageTitle = React.lazy(
  () =>
    import(
      /* webpackChunkName: "PageTitle-Chunk" */ './organisms/PageTitle/PageTitleWrapper'
    ),
);

const ImageGallery = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ImageGallery-Chunk" */ './organisms/ImageGallery/ImageGalleryWrapper'
    ),
);

const HeadlineAndText = React.lazy(
  () =>
    import(
      /* webpackChunkName: "HeadlineAndText-Chunk" */ './organisms/HeadlineAndTextBlock/HeadlineAndTextBlockWrapper'
    ),
);

const TextBlock = React.lazy(
  () =>
    import(
      /* webpackChunkName: "TextBlock-Chunk" */ './organisms/TextBlock/TextBlockWrapper'
    ),
);

const ContactGrid = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ContactGrid-Chunk" */ './organisms/ContactGrid/ContactGridWrapper'
    ),
);

const ContentCards = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ContentCards-Chunk" */ './organisms/ContentCards/ContentCardsWrapper'
    ),
);

const Footer = React.lazy(
  () =>
    import(
      /* webpackChunkName: "Footer-Chunk" */ './organisms/Footer/FooterWrapper'
    ),
);

const FullWidthMedia = React.lazy(
  () =>
    import(
      /* webpackChunkName: "FullWidthMedia-Chunk" */ './organisms/FullWidthMedia/FullWidthMediaWrapper'
    ),
);

const Header = React.lazy(
  () =>
    import(/* webpackChunkName: "Header" */ './organisms/Header/HeaderWrapper'),
);

const MyDocuments = React.lazy(
  () =>
    import(
      /* webpackChunkName: "MyDocuments-Chunk" */ './organisms/MyDocuments/MyDocuments'
    ),
);

const DashboardBanner = React.lazy(
  () =>
    import(
      /* webpackChunkName: "DashboardBanner-Chunk" */ './organisms/DashboardBanner/DashboardBanner'
    ),
);

const HighlightModule = React.lazy(
  () =>
    import(
      /* webpackChunkName: "HighlightModule-Chunk" */ './organisms/HighlightModule/HighlightModuleWrapper'
    ),
);

// Back-End work not yet compelted on B2B
const StorytellingModule = React.lazy(
  () =>
    import(
      /* webpackChunkName: "StorytellingModule-Chunk" */ './organisms/StorytellingModule/StorytellingModuleWrapper'
    ),
);

const Breadcrumb = React.lazy(
  () =>
    import(
      /* webpackChunkName: "Breadcrumb-Chunk" */ './organisms/Breadcrumb/BreadcrumbWrapper'
    ),
);

const BrandsAndSolutionsListing = React.lazy(
  () =>
    import(
      /* webpackChunkName: "BrandsAndSolutionsListing-Chunk" */ './organisms/BrandsAndSolutionsListing/BrandsAndSolutionsListing'
    ),
);

const ServiceAccordion = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ServiceAccordion-Chunk" */ './organisms/ServiceAccordion/ServiceAccordionWrapper'
    ),
);

const SimpleTable = React.lazy(
  () =>
    import(
      /* webpackChunkName: "SimpleTable-Chunk" */ './organisms/SimpleTable/SimpleTableWrapper'
    ),
);

const HeadlineAccordion = React.lazy(
  () =>
    import(
      /* webpackChunkName: "HeadlineAccordion-Chunk" */ './organisms/HeadlineAccordion/HeadlineAccordion'
    ),
);

const IconCards = React.lazy(
  () =>
    import(
      /* webpackChunkName: "IconCards-Chunk" */ './organisms/IconCardBlock/IconCardBlock'
    ),
);

const MediaHero = React.lazy(
  () =>
    import(
      /* webpackChunkName: "MediaHero-Chunk" */ './organisms/MediaHero/MediaHeroWrapper'
    ),
);

const ImageCardBlock = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ImageCardBlock-Chunk" */ './organisms/ImageCardBlock/ImageCardBlock'
    ),
);

const MarketingDownload = React.lazy(
  () =>
    import(
      /* webpackChunkName: "MarketingDownload-Chunk" */ './organisms/MarketingDownload/MarketingDownload'
    ),
);

const LinkCard = React.lazy(
  () =>
    import(
      /* webpackChunkName: "LinkCard-Chunk" */ './organisms/LinkCardBlock/LinkCardBlockWrapper'
    ),
);

const ChildPageNavigation = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ChildPageNavigation-Chunk" */ './organisms/ChildPageNavigation/ChildPageNavigation'
    ),
);

const ArticleHeader = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ArticleHeader-Chunk" */ './organisms/ArticleHeader/ArticleHeaderWrapper'
    ),
);

const ContactCards = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ContactCards-Chunk" */ './organisms/ContactCards/ContactCardsWrapper'
    ),
);

const ArticleStamp = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ArticleStamp-Chunk" */ './organisms/ArticleStamp/ArticleStampWrapper'
    ),
);

const DownloadCards = React.lazy(
  () =>
    import(
      /* webpackChunkName: "DownloadCards-Chunk" */ './organisms/DownloadCards/DownloadCardsWrapper'
    ),
);

const ArticleTags = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ArticleTags-Chunk" */ './organisms/ArticleTags/ArticleTagsWrapper'
    ),
);

const Pagination = React.lazy(
  () =>
    import(
      /* webpackChunkName: "Pagination-Chunk" */ './organisms/Pagination/Pagination'
    ),
);

const ArticleMedia = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ArticleMedia-Chunk" */ './organisms/ArticleMedia/ArticleMedia'
    ),
);
// Use format <BE Component Name>: <FE Component Name> if the component names differ.

export default {
  ArticleHeader,
  ArticleMedia,
  ArticleStamp,
  ArticleTags,
  BrandsAndSolutionsListing,
  Breadcrumb,
  ContactGrid,
  ContactCards,
  ContentCards,
  DashboardBanner,
  DownloadCards,
  DocumentManagementBlock: MyDocuments,
  Footer,
  FullWidthMedia,
  Header,
  HeadlineAccordion,
  HeadlineAndText,
  HighlightModule,
  IconCards,
  ImageCardBlock,
  ImageGallery,
  MarketingDownload,
  LinkCard,
  MediaHero,
  PageTitle,
  Pagination,
  QuoteImageBlock,
  ServiceAccordion,
  SimpleTable,
  StorytellingModule,
  TechnicalDownloadSearch,
  TextBlock,
  ChildPageNavigation,
};
